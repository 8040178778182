<template>
  <div class="box">
    <div class="content order-content">
      <van-swipe :autoplay="2000" class="banner">
        <van-swipe-item v-for="(image, index) in bannerList" :key="index">
          <a :href="image.hrefUrl">
            <img :src="image.imgUrl" alt="">
          </a>
        </van-swipe-item>
      </van-swipe>
      <cui-phone />
      <div class="area">
        <p class="min-title"><span>车牌号</span><span>&nbsp;</span></p>
        <div class="message">
          <p>{{orderInfo.plate||'暂未取证'}}</p>
          <p>&nbsp;</p>
        </div>
        <p class="min-title"><span>停车场</span><span>车位号</span></p>
        <div class="message">
          <p>{{parkInfo.parkName}}</p>
          <p>{{parkInfo.seatNum}}</p>
        </div>
        <p class="min-title"><span>停车费用</span><span>停车时长</span></p>
        <div class="message">
          <p>{{orderInfo.payMoney|money}}<span>元</span></p>
          <p v-html="orderInfo.timeHtml"></p>
        </div>
        <div class="intime">
          <div>
            <p class="title">入场时间</p>
            <p class="time">{{orderInfo.enterTime}}</p>
          </div>
          <van-image @click="imagePreview(orderInfo.inPic)" width="1rem" height="1rem" fit="cover" :src="orderInfo.inPic[0]" />
        </div>
      </div>

      <div class="lock" v-if="parkInfo.noRaiseLock=='1'&&orderInfo.inPic!=''">
        <p class="title">无欠费·不锁车·更便捷</p>
        <p v-if="arrearsList.length==0" class="section good">您信用良好，请自觉扫码支付，珍惜信用记录。</p>
        <p v-else class="section bad">您有历史欠费，爱车被锁。请交费后确认地锁下降再离场！</p>
      </div>

      <div class="area">
        <div class="paydata">
          <p>停车费</p>
          <p>￥{{orderInfo.payMoney|money}}</p>
        </div>
        <div class="paydata">
          <p>应付金额</p>
          <p>￥{{orderInfo.totalMoney|money}}</p>
        </div>
        <div class="paydata">
          <p>累计扣减</p>
          <p style="color:#FA8500">-￥{{orderInfo.discountMoney|money}}</p>
        </div>
      </div>
      <div class="area">
        <div class="paydata" v-if="according">
          <p>收费依据</p>
          <p>{{according}}</p>
        </div>
        <div class="paydata" v-if="rule">
          <p>收费规则</p>
          <p class="rule">{{rule}}</p>
        </div>
      </div>
      <p class="copy" style="margin-top:0.2rem;">&copy;{{companyName}}</p>
      <p class="copy">{{companyEnName}}</p>

    </div>
    <div class="paybox">
      <div class="price">支付<span>{{orderInfo.totalMoney|money}}</span>元</div>
      <div class="pay-btn" @click="showPaytype">立即付款</div>
    </div>
    <van-popup v-model="show" position="bottom">
      <div class="popup-box">
        <p class="title">请选择付款方式</p>
        <div class="pay-type" @click="payType='ali'" v-if="canUseAli">
          <img src="@/assets/ali.png" alt="" srcset="">
          <p>支付宝支付</p>
          <img :src="payType=='ali'?selectImg:unselectImg" alt="" srcset="">
        </div>
        <div class="pay-type" @click="payType='wx'" v-if="canUseWx">
          <img src="@/assets/wx.png" alt="" srcset="">
          <p>微信支付</p>
          <img :src="payType=='wx'?selectImg:unselectImg" alt="" srcset="">
        </div>
        <div class="pay-type" @click="payType='wxh5'" v-if="canUseWxh5">
          <img src="@/assets/wx.png" alt="" srcset="">
          <p>微信支付</p>
          <img :src="payType=='wxh5'?selectImg:unselectImg" alt="" srcset="">
        </div>
        <div class="pay-btn">
          <div class="pay-btn-group">
            <p @click="show=false">取消</p>
            <p @click="pay">确定</p>
          </div>
        </div>
      </div>
    </van-popup>
    <van-dialog v-model="showDialog.show" show-cancel-button>
      <p class="dialog">当前处于免费时长内，地锁将于<br /><span>{{showDialog.message|times}}</span><br />后升起，请注意安全。</p>
    </van-dialog>
  </div>
</template>

<script>
import selectImg from "@/assets/select.png";
import unselectImg from "@/assets/unselect.png";
import { ImagePreview } from "vant";
export default {
  name: "order",
  title: "当前订单",
  data() {
    return {
      aliAppId: process.env.VUE_APP_ALI_APPID,
      according: process.env.VUE_APP_ACCORDING,
      rule: process.env.VUE_APP_RULE,
      show: false,
      payType: "",
      canUseAli: false,
      canUseWx: false,
      canUseWxh5: false,
      selectImg: selectImg,
      unselectImg: unselectImg,
      orderInfo: {
        id: "",
        plate: "--",
        enterTime: "--:--:--",
        payMoney: 0,
        totalMoney: 0,
        discountMoney: 0,
        inPic: [],
        timeHtml: "",
      },
      parkInfo: {
        parkName: "--",
        parkingPosition: "--",
        seatNum: "--",
      },
      arrearsList: [],
      bannerList: [],
      companyName: process.env.VUE_APP_COMPANY_NAME,
      companyEnName: process.env.VUE_APP_COMPANY_ENGLISG_NAME,
      showDialog: {
        show: false,
        message: "",
        time: 0,
        timeInterval: null,
      },
    };
  },
  filters: {
    money: function (value) {
      return value.toFixed(2);
    },
    times: function (value) {
      if (value / 60 > 1) {
        let minute = Math.floor(value / 60);
        let secned = value - minute * 60;
        minute > 9 ? "" : (minute = `0${minute}`);
        secned > 9 ? "" : (secned = `0${secned}`);
        return `${minute} : ${secned}`;
      } else {
        value > 9 ? "" : (value = `0${value}`);
        return `00 : ${value}`;
      }
    },
  },
  created() {
    localStorage.seatNum = "";
    localStorage.orderId = "";
    localStorage.arrearsList = "";
    if (this.$route.query.seatNum) {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf("micromessenger") > 0) {
        if (!this.$route.query.code) {
          this.$wxlogin(encodeURIComponent(location.href));
        } else {
          this.canUseWx = true;
          this.payType = "wx";
          this.getOrderInfo(
            this.$route.query.seatNum,
            this.payType,
            this.$route.query.code
          );
        }
      } else if (ua.indexOf("alipayclient") > 0) {
        this.canUseAli = true;
        this.payType = "ali";
        window.ap.getAuthCode(
          {
            appId: this.aliAppId,
            scopes: ["auth_base"],
            showErrorTip: false,
          },
          (res) => {
            this.getOrderInfo(
              this.$route.query.seatNum,
              this.payType,
              res.authCode
            );
          }
        );
      } else {
        this.canUseWxh5 = this.canUseAli = true;
        this.payType = "h5";
        this.$toast({
          duration: 5000,
          message: "为保证信息安全\n请使用微信或支付宝扫码",
        });
      }
    } else {
      this.$toast.fail("未获取到车位信息，请重新扫描");
    }
    this.selectParkBanner();
  },
  methods: {
    selectParkBanner() {
      this.$http.post(
        "/park-service-system/banner/selectParkBanner",
        { currentPage: 1, pageSize: 99, type: "2" },
        (res) => {
          this.bannerList = res.list;
        },
        false
      );
    },
    getOrderInfo(seatNum, payType, code) {
      this.$http.post(
        "/park-service-order/order/selectParkingOrderDetail",
        { seatNum, payType, code },
        (res) => {
          this.parkInfo = res.orderParkDetailVO;
          this.$http.post(
            "/park-service-order/order/selectOrderDetail",
            { id: res.orderInDTO.id },
            (order) => {
              this.arrearsList = order.arrearsList;
              let { id, plate, enterTime, inPic, imageArrays } =
                order.orderInfo;
              if (imageArrays) {
                let obj = JSON.parse(imageArrays),
                  arr = [];
                for (const key in obj) {
                  if (Object.hasOwnProperty.call(obj, key)) {
                    arr.push(obj[key]);
                  }
                }
                inPic = arr;
              } else {
                inPic = [inPic];
              }
              let intime = enterTime.replace(/\-/g, "/");
              let times = new Date().getTime() - new Date(intime).getTime(),
                timeHtml;
              let resHours = 0,
                resMin = 0,
                resSec = 0;
              resSec = Math.floor(times / 1000);
              if (resSec > 59) {
                resMin = Math.floor(resSec / 60);
                if (resMin > 59) {
                  resHours = Math.floor(resMin / 60);
                  resMin = resMin - resHours * 60;
                  resSec = resSec - resMin * 60 - resHours * 3600;
                  timeHtml = `${resHours}<span>时</span>${resMin}<span>分</span>${resSec}<span>秒</span>`;
                } else {
                  resSec = resSec - resMin * 60;
                  timeHtml = `${resMin}<span>分</span>${resSec}<span>秒</span>`;
                }
              } else {
                timeHtml = `${resSec}<span>秒</span>`;
              }

              let { discountMoney, payMoney, totalMoney } = order;
              this.orderInfo = {
                id,
                plate,
                enterTime,
                timeHtml,
                inPic,
                discountMoney,
                payMoney,
                totalMoney,
              };
              if (order.orderInfo.freeTime) {
                this.showDialog.time = Math.floor(
                  order.orderInfo.freeTime / 1000
                );
                this.showDialog.show = true;
                this.showDialog.timeInterval = setInterval(() => {
                  this.showDialog.message = this.showDialog.time;
                  this.showDialog.time--;
                  if (this.showDialog.time < 1) {
                    clearInterval(this.showDialog.timeInterval);
                    this.showDialog.show = false;
                    window.location.reload();
                  }
                }, 1000);
              }
            }
          );
        }
      );
    },
    imagePreview(inPics) {
      ImagePreview(inPics);
    },
    showPaytype() {
      if (this.arrearsList.length > 0) {
        let totalPrice = 0;
        this.arrearsList.forEach((e) => {
          totalPrice += e.arrearsAmount;
        });
        this.$dialog
          .alert({
            title: `${this.orderInfo.plate}  车主请注意`,
            message: `该车牌有历史欠费订单未支付<br/>共<span style='font-size:20px;font-weight:bold;color:#ee0a24;'>${this.arrearsList.length}</span>笔，总计<span style='font-size:20px;font-weight:bold;color:#ee0a24;'>${totalPrice}</span>元<br/>请将欠费订单一并支付，感谢您的配合`,
            theme: "round-button",
            confirmButtonText: "一键缴纳",
          })
          .then(() => {
            this.$router.push(
              "/bill?code=" +
                this.$route.query.code +
                "&plate=" +
                this.orderInfo.plate
            );
          });
      } else if (this.orderInfo.totalMoney == 0) {
        this.$dialog
          .alert({
            title: "提示",
            message: "当前订单未产生费用，请直接离场",
            theme: "round-button",
            confirmButtonText: "我知道了",
          })
          .then(() => {});
      } else {
        this.show = true;
      }
    },
    // 聚合支付
    pay() {
      // 支付宝
      if (this.payType == "ali") {
        this.$http.post(
          "/park-service-order/pay/payOrder",
          {
            application: "0",
            code: "H5",
            orderIds: [this.orderInfo.id],
            payType: this.payType,
            returnUrl: location.origin + "/success",
          },
          (res) => {
            localStorage.orderId = this.orderInfo.id;
            document.write(res.resultInfo);
          }
        );
      }
      // 微信支付
      if (this.payType == "wx" || this.payType == "wxh5") {
        this.$http.post(
          "/park-service-order/pay/payOrder",
          {
            application: "0",
            code: this.$route.query.code,
            orderIds: [this.orderInfo.id],
            payType: this.payType,
          },
          (res) => {
            res.package = res.packageValue;
            window.WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              res,
              (payres) => {
                if (payres.err_msg == "get_brand_wcpay_request:ok") {
                  localStorage.orderId = this.orderInfo.id;
                  this.$router.replace("/success");
                } else {
                  this.$toast.fail("支付取消，请重新扫码");
                }
              }
            );
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-content {
  height: calc(100% - 1.16rem) !important;
  position: relative;
}
.banner {
  img {
    width: 7.5rem;
    height: 3.6rem;
  }
}
.area {
  width: 6.9rem;
  background: #ffffff;
  border-radius: 12px;
  margin: 0.22rem auto 0 auto;
  .min-title {
    display: flex;
    justify-content: space-between;
    padding: 0 0.24rem 0.24rem 0.24rem;
    &:first-child {
      padding-top: 0.38rem;
    }
    span {
      font-size: 0.24rem;
    }
  }
  .message {
    display: flex;
    justify-content: space-between;
    padding: 0 0.24rem 0.46rem 0.24rem;
    p {
      font-size: 0.38rem;
      font-weight: bold;
      span {
        font-size: 0.24rem;
        font-weight: normal;
      }
    }
  }
  .intime {
    display: flex;
    justify-content: space-between;
    padding: 0 0.24rem 0.46rem 0.24rem;
    .title {
      font-size: 0.24rem;
      padding-bottom: 0.24rem;
    }
    .time {
      font-size: 0.3rem;
      font-weight: bold;
    }
  }
  .line {
    width: 6.5rem;
    height: 1px;
    background-color: #f0f3f0;
    margin: 0 auto 0.36rem auto;
  }
  .paydata {
    display: flex;
    justify-content: space-between;
    padding: 0.24rem 0.35rem;
    font-size: 0.3rem;
    .rule {
      width: 4rem;
      font-size: 14px;
    }
  }
}

.lock {
  width: 6.9rem;
  margin: 0.22rem auto 0 auto;
  .title {
    background: linear-gradient(90deg, #53c06e 0%, #29bd95 100%);
    color: #fff;
    padding: 0 0.2rem;
    border-radius: 6px 4px 6px 2px;
    line-height: 2em;
    margin-bottom: 0.1rem;
    display: inline-block;
  }
  .section {
    padding: 0 0.25rem;
    font-size: 13px;
    border-radius: 3px;
    line-height: 2.6em;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #f5f5f5;
      position: absolute;
      z-index: 9;
      top: 0;
      bottom: 0;
      left: -6px;
      margin: auto;
    }
    &::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #f5f5f5;
      position: absolute;
      z-index: 9;
      top: 0;
      bottom: 0;
      right: -6px;
      margin: auto;
    }
  }
  .good {
    background: #ffcaad;
    color: #3dbe83;
  }
  .bad {
    background: #ff3638;
    color: #fff;
  }
}

.copy {
  text-align: center;
  color: #999;
  font-size: 12px;
  margin: 0.1rem 0;
}
.paybox {
  height: 1.16rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 0.3rem;
  line-height: 1.16rem;
  .price {
    padding-left: 0.54rem;
    span {
      color: #fa8500;
      font-size: 0.48rem;
    }
  }
  .pay-btn {
    color: #fff;
    font-size: 0.36rem;
    padding: 0 0.37rem;
    background: linear-gradient(270deg, #1a92fe 0%, #55bafd 80%);
  }
}
.popup-box {
  .title {
    font-size: 0.3rem;
    font-weight: bold;
    padding: 0 0.47rem;
    margin: 0.4rem 0;
  }
  .pay-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.43rem;
    margin: 0.43rem 0;
    img {
      width: 0.44rem;
      height: 0.44rem;
    }
    p {
      flex: 1;
      padding: 0 0.14rem;
      font-size: 0.28rem;
    }
  }
  .pay-btn {
    margin-top: 1.1rem;
    height: 1.16rem;
    box-shadow: 0px -8px 16px 0px rgba(237, 237, 237, 0.55);
    .pay-btn-group {
      float: right;
      margin-top: 0.23rem;
      margin-right: 0.3rem;
      width: 3.74rem;
      height: 0.7rem;
      display: flex;
      border-radius: 100px;
      overflow: hidden;
      p {
        flex: 1;
        text-align: center;
        background: #03c891;
        color: #fff;
        line-height: 0.7rem;
        &:last-child {
          background-color: #1ca6ff;
        }
      }
    }
  }
}
.dialog {
  padding: 0.5rem 0.2rem;
  text-align: center;
  font-weight: bold;
  /deep/ span {
    color: red;
    font-size: 1.4em;
  }
}
</style>